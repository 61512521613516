<script>
import { ViaCepApi } from '../../../apis/viaCep'
export default {
    data(){
        return {
            representationType: '',
            representativeSecond: false,
            inputData: {
              zip_code: ''
            },
            inputInfos: {},
            inputErrors: {},
            index: 1,
        }
    },
    props:{
        initialRepresentationType: 'user_responsible',
        hasSecondResponsible: Boolean,
        actionName: String
    },
    mounted() {
      const formData = sessionStorage.getItem('form_data');
      console.log(formData)
      if (formData && formData != 'undefined' && this.actionName == 'create') {
        this.inputData = JSON.parse(formData);
      
      } else if (this.actionName == 'new'){
        sessionStorage.removeItem('form_data');
      }
    }, 

    beforeMount() {
        this.representationType = this.initialRepresentationType;

        const savedRepresentativeSecond = sessionStorage.getItem('representativeSecond');
        if (savedRepresentativeSecond !== null) {
            this.representativeSecond = JSON.parse(savedRepresentativeSecond);
        }

        const savedRepresentationType = sessionStorage.getItem('representationType');
        if (savedRepresentationType) {
            this.representationType = savedRepresentationType;
        }
        this.representativeSecond = this.hasSecondResponsible
    },
    methods: {
        representationType(newVal) {
            sessionStorage.setItem('representationType', newVal);
        },
      
        fieldHasError(field){
          return Object.keys(this.inputErrors).includes(field); 
        },
        fieldErrorMessage(field){
          return this.inputErrors[field];
        },
        fieldHasInfo(field){
          return Object.keys(this.inputInfos).includes(field); 
        },
        fieldInfoMessage(field){
          return this.inputInfos[field];
        },

        fillAddressDataFromCEP() {
            const zip_code = this.inputData.zip_code
            this.infoDataMessage = false;
            this.inputInfos.zip_code = 'Procurando endereço...';
            clearTimeout(this.searchAddressTimer);
            this.searchAddressTimer = setTimeout(() => {
              console.log(this.inputData.zip_code)
              if (this.inputData.zip_code !== '') {
                ViaCepApi.getAddress({
                  term: zip_code.replace('-', ''),
                  onSuccess: (response) => {
                    
                    const addressData = response.data;
                    console.log(addressData)
                    if (addressData) {
                        this.inputData.address = addressData.address;
                        this.inputData.neighborhood = addressData.neighborhood;
                        this.inputData.city = addressData.city;
                        this.inputData.uf = addressData.state;
                        sessionStorage.setItem('form_data', JSON.stringify(this.inputData));
                        delete this.inputInfos.zip_code
                    } else if (addressData === null) {
                      this.infoDataMessage = true;
                      this.inputInfos.zip_code = 'CEP inválido';
                      this.inputData.address = '';
                      this.inputData.neighborhood = '';
                      this.inputData.city = '';
                      this.inputData.uf = '';
                    }
                    this.index++
                  },
                  onError: (error) => {
                    console.error('Error fetching address:', error);
                  }
                });
              } else {
                this.infoDataMessage = true;
                this.inputInfos.zip_code = 'CEP vazio';
              }
              this.index++
            }, 2000);
            this.index++
        }
    },
    beforeDestroy() {
        sessionStorage.removeItem('representativeSecond');
        sessionStorage.removeItem('representationType');
       
    }
}
</script>